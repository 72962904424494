// @file
// The major stylesheet for this theme.
//
// We categorize our components by creating headings in this file. See the
// description on the style guide home page for more information.
// Colors and Sass
//
// Documentation for colors and Sass mixins and variables.
//
// Weight: -1
//
// Style guide: sass
@import 'init';
// Defaults
//
// These are the default base styles applied to HTML elements.
//
// Component classes can override these styles, but if no class applies a style
// to an HTML element, these styles will be the ones displayed.
//
// Style guide: base
// Ensure fonts get loaded first to minimize front-end performance impact.
@import 'base/fonts';
@import 'base/normalize';
// Layouts
//
// The layout styling for major parts of the page that are included with the
// theme. Note: some Panels layouts are included in other parts of the system
// and are not documented.
//
// Style guide: layouts
@import 'layouts/layout-3col/layout-3col';
@import 'layouts/layout-center/layout-center';
@import 'layouts/layout-swap/layout-swap';
// Components
//
// Design components are reusable designs that can be applied using just the CSS
// class names specified in the component.
//
// Weight: 1
//
// Style guide: components
@import 'components/box/box';
@import 'components/clearfix/clearfix';
@import 'components/comment/comment';
@import 'components/divider/divider';
@import 'components/header/header';
@import 'components/hidden/hidden';
@import 'components/highlight-mark/highlight-mark';
@import 'components/inline-links/inline-links';
@import 'components/inline-sibling/inline-sibling';
@import 'components/messages/messages';
@import 'components/print-none/print-none';
@import 'components/responsive-video/responsive-video';
@import 'components/superfish-menu/superfish-menu';
@import 'components/visually-hidden/visually-hidden';
@import 'components/watermark/watermark';
@import 'components/wireframe/wireframe';
// Navigation
//
// Navigation components are specialized design components that are used for
// page navigation.
//
// Weight: 2
//
// Style guide: navigation
@import 'navigation/breadcrumb/breadcrumb';
@import 'navigation/more-link/more-link';
@import 'navigation/nav-menu/nav-menu';
@import 'navigation/navbar/navbar';
@import 'navigation/pager/pager';
@import 'navigation/skip-link/skip-link';
@import 'navigation/tabs/tabs';
// Forms
//
// Form components are specialized design components that are applied to forms
// or form elements.
//
// Weight: 3
//
// Style guide: forms
@import 'forms/autocomplete/autocomplete';
@import 'forms/button/button';
@import 'forms/collapsible-fieldset/collapsible-fieldset';
@import 'forms/form-item/form-item';
@import 'forms/form-table/form-table';
@import 'forms/progress-bar/progress-bar';
@import 'forms/progress-throbber/progress-throbber';
@import 'forms/resizable-textarea/resizable-textarea';
@import 'forms/table-drag/table-drag';


.front main .block:not(#block-views-solutions-slideshow-block-1) {
  border-top: solid #d7f1fb 20px;
}
.front main .block {
  overflow: hidden;
}

#block-views-index-block-about-us {
  border-bottom: solid #d7f1fb 20px;
  margin-top: 20px;
}

.view-index {
  &.view-display-id-block_about_us {
    clear: both;

    header {
      text-align: center;
    }
    h2 {
      border: 0;
    }
  }

  &.view-display-id-block_social_media {
    clear: both;

    header {
      display: none;
    }
  }

  &.view-display-id-block_partners {
    clear: both;

    header {
      text-align: center;
    }
    h2 {
      border: 0;
    }
  }
  &.view-display-id-page > .view-content {
    .views-row {
      @include respond-to(m) {
        float: left;
        width: 50%;
      }

      &-1 {
        @include respond-to(m) {
          padding-right: 2em;
        }
      }

      &-2 {
        @include respond-to(m) {
          padding-left: 2em;
        }
      }

    }
  }
  .links {
    text-align: right;

    li {
      padding: 0;
    }

    a {
      display: inline-block;
      background: image-url('../images', 'arrow.svg') center center / contain no-repeat;
      width: 32px;
      height: 32px;
      color: transparent;
      font-size: 1px;
      overflow: hidden;

      &:hover {
        text-decoration: none;
        color: transparent;
        background-image: image-url('../images', 'arrow-hover.svg');
      }
    }
  }
}

article header a {
  text-decoration: none;
}

.view-solutions-slideshow {
  max-width: 1200px;
  //margin: auto;
  position: relative;
  //border-top: solid color(hatter-blue) 1px;
  //border-bottom: solid color(hatter-blue) 1px;
  padding: 0;
  color: #fff;
  //@include respond-to(m) {
  //  padding: 1em;
  //}

  .views-row {
    padding: 0 1em;
    color: black;

    //&-1 {
    //  background: #cfdce9;
    //}
    //&-2 {
    //  background: #cce8f5;
    //}
    //&-3 {
    //  background: #d7f1fb;
    //}
    //&-4 {
    //  background: #cce3f1;
    //}
  }

  .views_slideshow_slide {
    width: 100% !important;
  }

  .views-field-field-solution-images {
    float: left;
    width: 100%;
    max-width: 300px;
    margin-left: 10px;
    margin-right: 10px;

    @include respond-to(m) {
      //width: auto;
      width: 100%;
      max-width: none;
      float: none;
    }

    .field-content {
      display: flex;
      margin: 0 -5px;
      width: 100%;

      > * {
        padding: 0 5px;
      }
    }

    img {
      width: 100%;

      @include respond-to(m) {
        width: auto;
      }
    }
  }

  .views-field-title,
  .views-field-field-solution-description {
    margin-left: 10px;
    margin-right: 10px;
    float: left;
    clear: left;
  }

  .views-slideshow-controls-text-previous,
  .views-slideshow-controls-text-next {
    position: absolute;
    top: 50%;
    z-index: 10;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none;
  }

  .views-slideshow-controls-text-previous {
    left: -50px;
  }

  .views-slideshow-controls-text-next {
    right: -50px;
  }

  .views-slideshow-controls-text-pause {
    display: none;
  }

  .views-slideshow-controls-bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h2 {
    border: 0;
  }

  p {
    margin: .5rem 0;
  }

  //a {
  //  color: white;
  //}
}

.view-id-index {
  max-width: 1200px;
  margin: auto;
}

#block-views-solutions-slideshow-block {
  //background: color(hatter-blue);
  //box-shadow: 0 5px 10px #444;
  //color: white;
  width: 50%;
  text-align: right;

  > * {
    display: inline-block;
    width: 600px;
    max-width: 100%;
    text-align: left;
  }
}

#block-views-solutions-slideshow-block-1 {
  //width: 50%;
  margin: 0 auto;

  > * {
    //width: 600px;
    //max-width: 100%;
    max-width: 1200px;
  }

  .view-content {
    display: flex;
    flex-wrap: wrap;
  }

  .views-row {
    width: 50%;
  }

  h2 {
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 1.25rem;
  }
}

.page-contact .layout-center,
.page-csapat .layout-center,
.page-taxonomy-term .layout-center,
.page-partnerek .layout-center,
.page-szolgaltatasok .layout-center,
.page-node .layout-center {
  max-width: 1200px;
  margin: auto;
  float: none;
}

.sf-menu.sf-style-light-blue li,
.sf-menu.sf-style-light-blue.sf-navbar,
.sf-menu.sf-style-light-blue li li,
.sf-menu.sf-style-light-blue li li li,
.sf-menu.sf-style-light-blue li:hover,
.sf-menu.sf-style-light-blue li.sfHover,
.sf-menu.sf-style-light-blue a:focus,
.sf-menu.sf-style-light-blue a:hover,
.sf-menu.sf-style-light-blue a:active,
.sf-menu.sf-style-light-blue span.nolink:hover,
.sf-menu.sf-style-light-blue.sf-navbar li li {
  background: white;
}

.sf-menu.sf-style-light-blue {
  a {
    color: color(link);
    text-decoration: none;
    border: 0;
    font-weight: bold;
    padding-right: 0;

    &:hover {
      color: black;
      background-color: transparent;
    }
  }
}

#boxes-box-quick_contact {
  text-align: right;
}

#boxes-box-copyright {
  text-align: center;
  border-top: solid #ccc 1px;
  background: #444;
  padding: .5rem;
  color: white;
  font-size: .75em;

  p {
    margin: 0;
  }
}

.view-mode-full {
  //font-size: 1.25em;
  line-height: 1.25;

  .field-name-field-solution-images {
    float: right;
  }
}

.contact-map #map {
  width: 100%;
  height: 300px;
}

#block-views-case-studies-index-block {
  clear: both;
  overflow: hidden;

  h2 {
    text-align: center;
    border: 0;
  }
}

.view-case-studies-index {
  clear: both;
  max-width: 1200px;
  margin: auto;

  .case-studies-read-more {
    a {
      display: inline-block;
      background: image-url('../images', 'arrow.svg') center center / contain no-repeat;
      width: 32px;
      height: 32px;
      color: transparent;
      font-size: 1px;
      overflow: hidden;

      &:hover {
        text-decoration: none;
        color: transparent;
        background-image: image-url('../images', 'arrow-hover.svg');
      }
    }
  }
}

.view-case-studies-index {
  .views-row {
    padding: 0 1rem;
    margin-bottom: 1rem;

    @include respond-to(m) {
      float: left;
      width: 25%;

      &:nth-child(4n+2) {
        clear: left;
        padding-left: 0;
      }

      &:nth-child(4n+1) {
        padding-right: 0;
      }
    }

    .views-field-field-case-study-images {
      height: 220px;
    }
  }

  img {
    //border: solid color(hatter-blue) 1px;
  }

  .views-limit-grouping-group {
    float: none;
    clear: both;
    border-bottom: solid #e8f7fd 10px;
    overflow: hidden;
    padding-bottom: 1rem;

    &:last-child {
      border-bottom: 0;
    }

    h3 {
      border: 0;
      margin-top: .5rem;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .views-field-title {
    margin-top: 1rem;
  }
}

.case-studies-read-more {
  text-align: right;
  float: right;
  clear: both;
}

.view-colleagues {
  .views-row {
    margin-bottom: 3em;

    @include respond-to(xs) {
      float: left;
      width: 50%;

      &:nth-child(2n+1) {
        clear: left;
      }
    }

    @include respond-to(m) {
      float: left;
      width: 25%;

      &:nth-child(2n+1) {
        clear: none;
      }

      &:nth-child(4n+1) {
        clear: left;
      }
    }
  }

  .field-name-field-colleague-position {
    height: 4em;
  }

  img {
    // border: solid color(hatter-blue) 1px;
  }
}

.view-partners {
  .views-row {
    margin-bottom: 3em;

    @include respond-to(xs) {
      float: left;
      width: 50%;

      &:nth-child(2n+1) {
        clear: left;
      }
    }

    @include respond-to(m) {
      float: left;
      width: (100%/6);

      &:nth-child(2n+1) {
        clear: none;
      }

      &:nth-child(6n+1) {
        clear: left;
      }
    }
  }

  .field-name-field-partner-logo {
    position: relative;
    height: 220px;
    line-height: 220px;
  }

  .field-name-title {
    text-align: center;
    min-height: 4em;

    h2 {
      border-top: solid #d7f1fb 5px;
      border-bottom: 0;
      font-size: .8rem;
      line-height: 1.1;
      margin-top: 0;
      padding-top: .5em;
    }
  }

  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    max-width: 90%;
  }
}

.node-type-case-study .field-name-field-case-study-images {
  .field-item {
    margin-bottom: 3rem;
    @include respond-to(m) {
      float: left;
      width: 25%;
    }
  }

  img {
    // border: solid color(hatter-blue) 1px;
  }
}

.node-case-study.view-mode-teaser {
  margin-bottom: 3rem;
  @include respond-to(m) {
    float: left;
    width: 25%;
    padding: 1rem;

    &:nth-child(4n) {
      padding-left: 0;
      clear: left;
    }
    &:nth-child(4n+3) {
      padding-right: 0;
    }
  }

  .view-case-studies .view-content *:nth-child(4n+1) & {
    clear: left;
  }

  img {
    // border: solid color(hatter-blue) 1px;
  }
}

.section-elerhetosegek #block-boxes-contact-map {
  @include respond-to(m) {
    float: right;
    width: 50%;
    #map {
      height: 500px;
    }
  }
}

div.sf-accordion-toggle.sf-style-light-blue {
  position: fixed;
  top: 30px;
  right: 10px;
  z-index: 900;

  a {
    font-size: 32px;
    padding: .25em .5em;
    text-decoration: none;
    font-weight: normal !important;
    background: color(hatter-blue) !important;
    color: white;
  }
}

ul.sf-menu.sf-accordion.sf-expanded {
  position: fixed;
  top: 68px !important;
  right: 10px !important;
  left: 10px !important;
  border: solid #017fba 1px;
  width: auto;
}

.sf-clone-parent {
  display: none;
}

#block-locale-language,
#block-boxes-quick-contact,
#block-boxes-quick-contact-en {
  float: right;
}

#block-locale-language ul {
  padding: 1.5rem 0 0 .5rem;
  margin: 0;
  display: inline-block;
}

#block-locale-language li {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
}

#sliding-popup .popup-content #popup-text h2 {
  font-weight: normal;
  line-height: 3em;
}

#sliding-popup .popup-content #popup-text .button {
  margin-top: 1em;
}

#sliding-popup .find-more-button {
  display: none !important;
}

.not-front {
  .layout-3col__left-sidebar {
    width: 50%;
    margin-right: 50%;
    text-align: right;

    > * {
      text-align: left;
      display: inline-block;
      padding-right: 2em;
      width: 480px;
      padding-top: 2em;
      max-width: 100%;
    }
  }

  .layout-3col__right-content {
    max-width: 600px;
    width: 50%;
    margin-left: 50%;
  }

  &.node-type-case-study .layout-3col__right-content {
    width: 100%;
    max-width: none;
    margin-left: 0;
  }
}

//.layout-3col__full {
//  max-width: 1200px;
//  margin: 0 auto;
//}

.layout-center {
  padding-top: 128px;
  position: relative;

  &::before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 128px;
    z-index: 100;
    display: block;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  }
}

.region-highlighted {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  //background: #017fba;
}

.view-solutions {
  .view-content {
    display: flex;
    flex-wrap: wrap;
  }

  .views-row {
    width: 50%;
  }

  .field-name-field-solution-images {
    .field-item {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }
}

.view-images-slideshow {
  overflow: hidden;

  .views-row {
    float: left;
    width: 380px;
    margin-right: 30px;

    img {
      max-width: 100%;
    }

    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }

  .not-front:not(.section-elerhetosegek) & {
    padding-bottom: 1.5rem;
    border-bottom: solid #d7f1fb 20px;
  }
}

.sm-icon {
  font-size: 1px;
  overflow: hidden;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: center center / contain no-repeat;
  color: transparent;
  margin: 0 1em;

  &:hover,
  &:focus,
  &:active {
    color: transparent !important;
    text-decoration: none !important;
  }

  &--facebook {
    background-image: image-url('../images', 'facebook.svg');
  }

  &--youtube {
    background-image: image-url('../images', 'youtube.svg');
  }
}


.not-front h1 {
  font-size: 1.25rem;
  border-bottom: solid #d7f1fb 20px;
}
