// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header,
%header {
  @extend %clearfix;
  margin: auto;
  position: fixed;
  width: 100%;
  height: 128px;
  background: white;
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 900;
  top: 0;

  // Wrapping link for logo.
  &__logo {
    float: left;
    margin: 0 10px 0 0;
    padding: 0;

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Logo image.
  &__logo-image {
    margin-top: 1.5rem;
    vertical-align: bottom;
    max-height: 64px;
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    position: absolute;
    left: 5px;
    bottom: 10px;
    color: #939594;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    clear: right;
    float: right;
  }
}
